
import * as ipxRuntime$p2EOkJLJu0 from '/app/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "mobile": 460,
    "tablet": 740,
    "laptop": 1080,
    "desktop": 1600,
    "tv": 1920
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "charon.justchunks.net",
    "dunb17ur4ymx4.cloudfront.net"
  ],
  "alias": {
    "/charon": "https://charon.justchunks.net/v1/file-references/file"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$p2EOkJLJu0, defaults: {} }
}
        